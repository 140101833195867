import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Cookies = () => (
  <div className={`bg-gray-700`}>
  <Layout>
    <SEO title="Cookies" />
      <section className={`container px-5 mx-auto mb-20`}>
        <div className={`flex flex-wrap text-white`}>
        {/* <!-- intro-content --> */}
          <div className={`w-full sm:mx-16 `}>
            <h1 className={`mb-2 font-hairline  md:border-b-0 lg:border-b-2 text-2xl md:text-3xl lg:text-4xl sm:text-center sm:leading-none sm:pb-3 xl:leading-tight lg:text-left`}>Cookies</h1>
            <div className={``}>
              <h3>How we use them</h3>
              <p>My site uses cookies in order to distinguish you from other users. You are able to disable cookies which will prevent your visit being tracked. Some cookies are essential for the website to work properly. I would advise you to review and if necessary disable cookies on your internet browser regularly. Please see http://www.youronlinechoices.com/uk/ to provide you with information about cookies and http://www.aboutcookies.org/ for advice about deleting and managing cookies.</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
    </div>
)

export default Cookies
